body {
    font-family: "proxima-nova", sans-serif;
    color: #606060;
    font-size: 18px;
    line-height: 1.2;
}

h1, h2, h3, h4 {
    color: #181e21;
}
h2 {
    font-size: 50px;
}

.clear-float {
    clear:both;
}

.highlight {
    color: #F6EB00;
}
p .highlight, p.highlight,
h4 .highlight, h4.highlight {
    font-size: 1.5em;
}
.highlight-bg {
    display: inline;
    line-height: 1.4;
    background-color: #F6EB00;
    box-shadow: 0.1em 0px 0px #F6EB00, -0.1em 0px 0px #F6EB00; /* emulate padding */
}
.headingbit{
    font-size:40px;
    font-weight: 600;
}
.content-text-narrow{
    width: 20vw;
}
img.display-left {
    float:left;
    margin-right:20px;
    margin-bottom:20px;
}
img.display-right {
    float:right;
    margin-left:20px;
    margin-bottom:20px;
}
@media only screen and (max-width: 760px){
    h2 {
        font-size: 1.5em;
    }
    img.display-left,
    img.display-right {
        float: none;
        margin: 20px 0;
    }
}
a {
    cursor:pointer;
    color:inherit;
    display: inline-block;
    background-color: #F6EB00;
}
[data-edit] a > img {
        background-color: #fff;
}
.header-bar-top img {
    margin:40px 0px 0px 40px;
}
.guide-to-future ul {
    list-style-type:none;
}
.top-menu {
    margin-right:20px;
}
.top-menu a {
    color:#292929;
}
.navbar {
    width:50%;
    float:right;
    overflow: hidden;
    margin-right:20px;
}
.navbar a {
    float: right;
    color: white;
    text-align: center;
    font-size:12px;
    padding: 14px 16px;
    text-decoration: none;
    background:none;
}
.dropdown {
    overflow: hidden;
    float:right;
}
.dropdown .dropbtn {
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-size: 12px;
    margin: 0;
}
.dropdown-content {
    display: none;
    right:51%;
    position: absolute;
    background-color:#0D1319;
    width: 400px;
    z-index: 1;
}
.dropdown-content h3 {
    padding-left:20px;
}
.dropdown-content a {
    float: none;
    color: #ffffff;
    text-decoration: none;
    background:none;
    display: block;
    text-align: left;
}
.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-left {
    display: inline-block;
    width: 50%;
    height: 100%;
}
.dropdown-right {
    display: inline-block;
    padding-top:0;
    vertical-align:top;
}
.slideshow-container {
    max-width: 1000px;
    position: inline;
    margin: auto;
}
.mySlides {
    display: none;
}
.mySlides img {
    min-height: 100%;
    width:100%;
    height:auto;
    object-fit:cover;
}
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}
@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
.text-explore {
    position: absolute;
    bottom: 25px;
    right: 100px;
}
.dots-container {
    margin: 70px 0px 0px 20px;
}
.text-explore a {
    text-decoration:none;
    background:none;
}
h1 {
    text-align:center;
    font-size: 80px;
    padding: 50px 0;
    background-color: inherit;
    margin: 0;
}
.banner-img a.banner.banner-image {
    margin: 20px;
    width: 275px;
    height: 147px;
}
.banner-img.banner-image {
    max-width:100%;
    max-height:100%;
}

.contact-panel-section {
    padding: 50px;
    padding-bottom: 60px;
    background-color:#101820;
    text-align:center;
    color:#ffffff;
}
.contact-panel-section p {
    font-size:20px;
}
.a-about-content {
    margin:0 auto;
    max-width: 1200px;
}
.about-left {
    width:50%;
    display:inline-block;
}
.about-right {
    width:45%;
    vertical-align:top;
    display:inline-block;
}

.about-content-text {
    padding: 30px;
}
.about-content-text a {
    color:#000000;
    text-decoration:none;
    background:none;
}
.about-content-text p {
    padding-top:20px;
    vertical-align:top;
}
.learn-more ul li a {
    padding:0;
    margin:10px 0px 0px 0px;
    list-style-type:none;
    text-decoration:none;
    background:none;
    color:#000000;
    font-size:10px;
}
.blog-page-content {
    text-align:center;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px auto;
}
.blog-page-content a {
    background:none;
}
#more > div {
    display:inline-block;
}

#more {
    display:none;
}
.thebutton {
    font-size: 14px;
    background: none;
    padding: 15px;
    color: #F6EB00 !important;
    border: 2px solid #F6EB00;
    text-transform: uppercase;
    border-radius: 30px;
    transition: all 0.25s ease;
    text-decoration:none;
}
.thebutton:hover {
    background: #000000;
}
.thebutton2 {
    font-size: 14px;
    background: none;
    padding: 15px;
    color: #000000 !important;
    border: 2px solid #F6EB00;
    text-transform: uppercase;
    border-radius: 30px;
    transition: all 0.25s ease;
    text-decoration:none;
    background:none;
}
.thebutton2:hover {
    background: #000000;
    color: #F6EB00 !important;
}
.spoiler {
    outline:1px solid black;
    text-decoration:underline;
}
.js .spoiler {
    position:relative;
    outline:none;
}
.js .spoiler::after {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    background:black;
    opacity:1;
    transform: rotate(-5deg);
    transition:opacity 0.5s ease, transform 0.5s ease;
    animation: spoilerjiggle 1s infinite;
}
.js .spoiler.open::after {
    opacity:0;
    transform: rotate(5deg);
}
@keyframes spoilerjiggle {
    0% {transform: rotate(5deg);}
    80% {transform: rotate(5deg) translateY(0);}
    90% {transform: rotate(5deg) translateY(2.5px);}
    95% {transform: rotate(5deg) translateY(-5px);}
    100% {transform: rotate(5deg);}
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:1rem;
    padding:0;
    margin:0;
    list-style: none;
    li{
        padding:30px;
    }
    @media screen and (max-width:768px) {
        grid-template-columns:1fr;
        li:nth-child(odd){
            padding-bottom: 0;
        }
        li:nth-child(even){
            padding-top: 0;
        }
    }
}